import { Heading } from '@/ui'

import { Meta } from '@/utils'

export default function Error404() {
	return (
		<Meta title="Page not found">
			<Heading title="404 - Page Not Found" />
		</Meta>
	)
}
